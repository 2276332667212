import React from 'react';
import type { StyledElement, TestAutomation } from 'contracts';
import Html from 'components/html';
import is from 'utils/is';
import toArray from 'utils/to-array';

import styles from './helper-text.module.scss';

interface HelperTextElement extends StyledElement, TestAutomation {
  error?: boolean;
  children: React.ReactNode;
}

const HelperText: React.FunctionComponent<HelperTextElement> = (props) => {
  const { error, children, className, style, testId } = props;
  const ref = React.useRef<HTMLDivElement>(undefined);
  const hidden = !children;

  React.useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.style.maxHeight = '';

    const height = ref.current.clientHeight;

    if (!is.bool(hidden) || !height) return;

    ref.current.style.maxHeight = `${height}px`;
  }, [error, children, hidden]);

  return (
    <Html.div
      className={[styles.helperText, hidden && styles.hidden, ...toArray(className)]}
      style={style}
      hidden={hidden}
      ref={ref}
    >
      <Html.div className={[styles.contents, error && styles.error]} typography="caption" testId={testId}>
        {is.string(children) && <Html.p className="m-0">{children}</Html.p>}
        {!is.string(children) && children}
      </Html.div>
    </Html.div>
  );
};

export type { HelperTextElement };
export default HelperText;
